import { Exercise } from '@chiroup/core';
import { MegaphoneIcon } from '@heroicons/react/24/solid';
import { useContext, useMemo, useState } from 'react';
import Header from '../common/Header';
import { hcVerbiage } from '../common/verbiage';
import PainLevelSurvey from '../components/PainLevelSurvey';
import { MeContext } from '../contexts/me.context';
import ExerciseList from './ExerciseList';

const Exercises = () => {
  const { me } = useContext(MeContext);
  const [open, setOpen] = useState(false);

  const exercisesByStatus = useMemo(() => {
    return me?.exercises?.reduce(
      (
        obj: {
          active: Exercise[];
          inactive: Exercise[];
        },
        exercise,
      ) => {
        obj[exercise.phase === 2 ? 'inactive' : 'active'].push(exercise);
        return obj;
      },
      { active: [], inactive: [] },
    );
  }, [me?.exercises]);

  return (
    <>
      <div>
        <Header
          title={hcVerbiage.exercises[me.language]}
          subtitle={hcVerbiage.exercisesDescription[me.language]}
          breadcrumbs={[
            {
              title: hcVerbiage.exercises[me.language],
              to: '/exercises',
            },
          ]}
          noBack
          buttons={
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2"
              style={{
                backgroundColor: me?.clinic?.primaryColor,
              }}
              onClick={() => setOpen(true)}
            >
              {hcVerbiage.notify[me.language]}
              <MegaphoneIcon
                className="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </button>
          }
        />
        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 md:max-w-7xl md:px-6 lg:px-8 py-6">
          <div className="flex flex-col gap-6 w-full">
            <ExerciseList exercises={exercisesByStatus.active} />
            {!!exercisesByStatus.inactive?.length && (
              <>
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-start">
                    <span className="bg-gray-50 pr-2 text-sm text-gray-500">
                      Upcoming
                    </span>
                  </div>
                </div>
                <ExerciseList exercises={exercisesByStatus.inactive} />
              </>
            )}
          </div>
        </div>
      </div>
      <PainLevelSurvey open={open} setOpen={setOpen} />
    </>
  );
};

export default Exercises;
