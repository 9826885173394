import { PlatformDetection } from '@ephox/sand';
import { useContext, useMemo } from 'react';
import Header from '../common/Header';
import { MeContext } from '../contexts/me.context';
import { classNames, formatPhone } from '@chiroup/core';
import {
  GlobeAltIcon,
  EnvelopeIcon,
  MapIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import { hcVerbiage } from '../common/verbiage';

const platform = PlatformDetection.detect();
const isiOS = platform.os.isiOS();

const Clinic = () => {
  const { me } = useContext(MeContext);

  const actions = useMemo(() => {
    const actionsArray = [];

    if (me.clinic.phone) {
      const formattedPhone = formatPhone(me.clinic.phone, true) || '';
      const callDescription = hcVerbiage.callDescription[me.language]
        .replace('{{clinicName}}', me.clinic.name || 'the clinic')
        .replace('{{phone}}', formattedPhone);
      actionsArray.push({
        title: hcVerbiage.call[me.language],
        descr: callDescription,
        href: `tel:${me.clinic.phone}`,
        icon: <PhoneIcon className="h-6 w-6" />,
        iconForeground: 'text-teal-700',
        iconBackground: 'bg-teal-50',
      });
    }

    if (me.clinic.address1) {
      const address = `${me.clinic.address1}${
        me.clinic.address2 ? ` ${me.clinic.address2}` : ''
      }${me.clinic.city ? ` ${me.clinic.city}` : ''}${
        me.clinic.state ? ` ${me.clinic.state}` : ''
      }${me.clinic.zip ? ` ${me.clinic.zip}` : ''}`;
      const addressEncoded = encodeURIComponent(address);
      const directionsDescription = hcVerbiage.directionsDescription[
        me.language
      ].replace('{{address}}', address);
      actionsArray.push({
        title: hcVerbiage.directions[me.language],
        descr: directionsDescription,
        href: isiOS
          ? `http://maps.apple.com/?q=${addressEncoded}`
          : `https://www.google.com/maps/dir//${addressEncoded}`,
        icon: <MapIcon className="h-6 w-6" />,
        iconForeground: 'text-purple-700',
        iconBackground: 'bg-purple-50',
      });
    }

    if (me.clinic.email) {
      actionsArray.push({
        title: hcVerbiage.email[me.language],
        descr: hcVerbiage.emailDescription[me.language],
        href: `mailto:${me.clinic.email}`,
        icon: <EnvelopeIcon className="h-6 w-6" />,
        iconForeground: 'text-sky-700',
        iconBackground: 'bg-sky-50',
      });
    }

    if (me.clinic.site) {
      const siteWithoutProtocol = me.clinic.site.replace(/^https?:\/\//, '');
      actionsArray.push({
        title: hcVerbiage.site[me.language],
        descr: hcVerbiage.siteDescription[me.language].replace(
          '{{clinicName}}',
          me.clinic.name || 'the clinic',
        ),
        href: `https://${siteWithoutProtocol}`,
        icon: <GlobeAltIcon className="h-6 w-6" />,
        iconForeground: 'text-yellow-700',
        iconBackground: 'bg-yellow-50',
        newTab: true,
      });
    }

    if (me.clinic.facebook) {
      let fbWithoutProtocol = me.clinic.facebook.replace(/^https?:\/\//, '');
      fbWithoutProtocol = fbWithoutProtocol.replace(/^www\.facebook\.com/, '');
      fbWithoutProtocol = fbWithoutProtocol.replace(/^facebook\.com/, '');
      actionsArray.push({
        title: 'Facebook',
        href: `https://www.facebook.com/${fbWithoutProtocol}`,
        descr: hcVerbiage.facebookDescription[me.language],
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V6.75Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M12.75 19V11.75C12.75 10.6454 13.6454 9.75 14.75 9.75H16.25"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M10.75 13.75H15.25"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        ),
        iconForeground: 'text-blue-700',
        iconBackground: 'bg-blue-50',
        newTab: true,
      });
    }

    if (me.clinic.twitter) {
      let twitterWithoutProtocol = me.clinic.twitter.replace(
        /^https?:\/\//,
        '',
      );
      twitterWithoutProtocol = twitterWithoutProtocol.replace(
        /^www\.twitter\.com/,
        '',
      );
      twitterWithoutProtocol = twitterWithoutProtocol.replace(
        /^twitter\.com/,
        '',
      );
      actionsArray.push({
        title: 'Twitter',
        href: `https://twitter.com/${twitterWithoutProtocol}`,
        descr: hcVerbiage.twitterDescription[me.language],
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
          >
            <path
              d="M9.31 18.25C14.7819 18.25 17.7744 13.4403 17.7744 9.26994C17.7744 9.03682 17.9396 8.83015 18.152 8.73398C18.8803 8.40413 19.8249 7.49943 18.8494 5.97828C18.2031 6.32576 17.6719 6.51562 16.9603 6.74448C15.834 5.47393 13.9495 5.41269 12.7514 6.60761C11.9785 7.37819 11.651 8.52686 11.8907 9.62304C9.49851 9.49618 6.69788 7.73566 5.1875 5.76391C4.39814 7.20632 4.80107 9.05121 6.10822 9.97802C5.63461 9.96302 5.1716 9.82741 4.75807 9.58305V9.62304C4.75807 11.1255 5.75654 12.4191 7.1444 12.7166C6.70672 12.8435 6.24724 12.8622 5.80131 12.771C6.19128 14.0565 7.87974 15.4989 9.15272 15.5245C8.09887 16.4026 6.79761 16.8795 5.45806 16.8782C5.22126 16.8776 4.98504 16.8626 4.75 16.8326C6.11076 17.7588 7.69359 18.25 9.31 18.2475V18.25Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        ),
        iconForeground: 'text-green-700',
        iconBackground: 'bg-green-50',
        newTab: true,
      });
    }

    if (me.clinic.youtube) {
      actionsArray.push({
        title: 'YouTube',
        href: me.clinic.youtube,
        descr: hcVerbiage.youtubeDescription[me.language].replace(
          '{{clinicName}}',
          me.clinic.name || 'our clinic',
        ),
        icon: (
          <svg
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            className="h-6 w-6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V6.75Z"
            ></path>
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M15.25 12L9.75 8.75V15.25L15.25 12Z"
            ></path>
          </svg>
        ),
        iconForeground: 'text-red-700',
        iconBackground: 'bg-red-50',
        newTab: true,
      });
    }

    return actionsArray;
  }, [me]);

  return (
    <div>
      <Header
        title={me?.clinic?.name || hcVerbiage.myClinic[me.language]}
        subtitle={me?.primaryClinician || hcVerbiage.clinicContact[me.language]}
        breadcrumbs={[
          {
            title: hcVerbiage.clinic[me.language],
            to: '/clinic',
          },
        ]}
        noBack
      />
      <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 md:max-w-7xl md:px-6 lg:px-8 py-6">
        <div className="rounded-lg bg-gray-300 overflow-hidden shadow divide-y divide-gray-300 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
          {actions.map((action, actionIdx) => (
            <div
              key={action.title}
              className={classNames(
                actionIdx === 0
                  ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                  : '',
                actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                actionIdx === actions.length - 1
                  ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                  : '',
                'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset',
              )}
            >
              <div>
                <span
                  className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    'rounded-lg inline-flex p-3 ring-4 ring-white',
                  )}
                >
                  {action.icon}
                </span>
              </div>
              <div className="mt-8">
                <h3 className="text-lg font-medium">
                  {action.newTab ? (
                    <a
                      href={action.href}
                      className="focus:outline-none"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="absolute inset-0" />
                      {action.title}
                    </a>
                  ) : (
                    <a href={action.href} className="focus:outline-none">
                      <span className="absolute inset-0" />
                      {action.title}
                    </a>
                  )}
                </h3>
                <p className="mt-2 text-sm text-gray-500">{action.descr}</p>
              </div>
              <span className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400">
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clinic;
