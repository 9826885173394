import { ChiroUpAPI } from '@chiroup/client-core';

const userService = () => {
  const me = async () => {
    return ChiroUpAPI.get('api', '/me', {});
  };

  const canResetPassword = async (username: string) => {
    return ChiroUpAPI.post('api-noauth', '/can-reset-password', {
      body: { username },
    });
  };

  const forgotPassword = async (username: string) => {
    return ChiroUpAPI.post('api-noauth', '/forgot-password', {
      body: { username },
    });
  };

  const forgotUsername = async (email: string) => {
    return ChiroUpAPI.post('api-noauth', '/forgot-username', {
      body: { email },
    });
  };

  const painLevelSurvey = async (level: number) => {
    return ChiroUpAPI.post('api', '/pain-level-survey', {
      body: { level },
    });
  };

  const survey = async (body: {
    patientId: string;
    surveyId: string;
    perBetter: number;
    timesTreated: number;
    experience: string;
    recommend: number;
  }) => {
    return ChiroUpAPI.post('api-noauth', '/survey', {
      body,
    });
  };

  const surveyEmails = async (unsubscribe: boolean) => {
    return ChiroUpAPI.put('api', '/survey-emails', {
      body: { unsubscribe },
    });
  };

  return {
    me,
    canResetPassword,
    forgotPassword,
    forgotUsername,
    painLevelSurvey,
    survey,
    surveyEmails,
  };
};

export default userService();
