import { prescription } from '@chiroup/core';
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/solid';
import { useContext, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../common/Header';
import ResponsivePlayer from '../common/ResponsivePlayer';
import { hcVerbiage } from '../common/verbiage';
import PainLevelSurvey from '../components/PainLevelSurvey';
import { MeContext } from '../contexts/me.context';
import { InfoItemHtml } from '@chiroup/components';

const Exercise = () => {
  const { me } = useContext(MeContext);
  const { exerciseId } = useParams();
  const [open, setOpen] = useState(false);

  const currentExercise = useMemo(() => {
    return me?.exercises?.find((e) => String(e.ID) === exerciseId);
  }, [me?.exercises, exerciseId]);

  const nextExerciseID = useMemo(() => {
    const currentExercise = me?.exercises?.find(
      (exercise) => String(exercise.ID) === exerciseId,
    );
    const exercisesForThisPhase = me?.exercises?.filter(
      (e) => e.phase === currentExercise?.phase,
    );
    const currentIndex = exercisesForThisPhase?.findIndex(
      (e) => String(e.ID) === exerciseId,
    );
    const nextExercise = exercisesForThisPhase?.[currentIndex + 1];
    return nextExercise?.ID;
  }, [exerciseId, me?.exercises]);

  const previousExerciseID = useMemo(() => {
    const currentExercise = me?.exercises?.find(
      (exercise) => String(exercise.ID) === exerciseId,
    );
    const exercisesForThisPhase = me?.exercises?.filter(
      (e) => e.phase === currentExercise?.phase,
    );
    const currentIndex = exercisesForThisPhase?.findIndex(
      (e) => String(e.ID) === exerciseId,
    );
    const previousExercise = exercisesForThisPhase?.[currentIndex - 1];
    return previousExercise?.ID;
  }, [exerciseId, me?.exercises]);

  const currentPhase = useMemo(() => {
    return me?.exercises?.find((e) => String(e.ID) === exerciseId)?.phase;
  }, [me?.exercises, exerciseId]);

  const navigate = useNavigate();

  const exercise = useMemo(() => {
    return me.exercises?.find((exercise) => exercise.ID === Number(exerciseId));
  }, [exerciseId, me]);

  const subtitle = useMemo(() => {
    if (!exercise) {
      return '';
    }
    return prescription(exercise, me.language);
  }, [exercise, me.language]);

  const handleOpen = (val: boolean) => {
    setOpen(val);
    if (!val && !previousExerciseID) {
      navigate('/exercises');
    }
  };

  return (
    <>
      <div>
        <Header
          title={exercise?.name || ''}
          subtitle={subtitle}
          back="/exercises"
          breadcrumbs={[
            {
              title: hcVerbiage.exercises[me.language],
              to: '/exercises',
            },
            {
              title: exercise?.name || '',
              to: `/exercises/${exerciseId}`,
            },
          ]}
          buttons={
            <>
              {!!previousExerciseID && (
                <Link to={`/exercises/${previousExerciseID}`}>
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    <ArrowLeftCircleIcon
                      className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                    {hcVerbiage.previous[me.language]}
                  </button>
                </Link>
              )}
              {nextExerciseID ? (
                <Link to={`/exercises/${nextExerciseID}`}>
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    style={{
                      backgroundColor: me?.clinic?.primaryColor,
                    }}
                  >
                    {hcVerbiage.next[me.language]}
                    <ArrowRightCircleIcon
                      className="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </Link>
              ) : currentPhase === 1 ? (
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  style={{
                    backgroundColor: me?.clinic?.primaryColor,
                  }}
                  onClick={() =>
                    handleOpen(currentExercise?.phase === 1 ? true : false)
                  }
                >
                  {hcVerbiage.complete[me.language]}
                  <CheckCircleIcon
                    className="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              ) : null}
            </>
          }
        />
        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 md:max-w-7xl md:px-6 lg:px-8 py-6">
          <div className="flex flex-col w-full">
            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="px-4 py-5 sm:p-6 flex flex-col gap-10">
                {!!exercise?.video && <ResponsivePlayer url={exercise.video} />}
                {!!exercise?.descr && (
                  <InfoItemHtml
                    className="text-gray-500 normal-styling"
                    value={exercise.descr}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PainLevelSurvey open={open} setOpen={handleOpen} />
    </>
  );
};

export default Exercise;
