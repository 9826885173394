import { stripHtmlTags } from '@chiroup/core';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/Header';
import { hcVerbiage } from '../common/verbiage';
import { MeContext } from '../contexts/me.context';

const Conditions = () => {
  const { me } = useContext(MeContext);

  return (
    <div>
      <Header
        title={hcVerbiage.conditions[me.language]}
        subtitle={hcVerbiage.conditionsDescription[me.language]}
        breadcrumbs={[
          {
            title: hcVerbiage.conditions[me.language],
            to: '/conditions',
          },
        ]}
        noBack
      />
      <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 md:max-w-7xl md:px-6 lg:px-8 py-6">
        <div className="flex flex-col w-full">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul className="divide-y divide-gray-300">
              {me.conditions?.map((condition) => (
                <li key={condition.ID}>
                  <Link
                    to={`/conditions/${condition.ID}`}
                    className="block hover:bg-gray-50"
                  >
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="min-w-0 flex-1 flex items-center">
                        {!!condition.commonImg && (
                          <div className="flex-shrink-0">
                            <div className="w-12 h-12 items-center flex">
                              <img
                                className="w-full h-auto rounded-full"
                                src={
                                  condition.commonImg
                                    ? condition.commonImg.startsWith('http')
                                      ? condition.commonImg
                                      : `${
                                          import.meta.env.VITE_S3_ASSETS_URL
                                        }/${condition.commonImg}`
                                    : ''
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        )}
                        <div className="min-w-0 flex-1 px-4 md:gap-4">
                          <div>
                            <p
                              className="text-sm font-medium truncate"
                              style={{
                                color: me?.clinic?.primaryColor,
                              }}
                            >
                              {condition.commonName}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <span className="truncate">
                                {stripHtmlTags(condition.descr)}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conditions;
